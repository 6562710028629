@import '../../globals.module.scss';

.modal {
    position: fixed;
    background-color: #414755;
    max-width: 600px;
    max-height: 170px;
    left: calc(-50vw + 50%);
    right: calc(-50vw + 50%);
    top: calc(-50vh + 50%);
    bottom: calc(-50vh + 50%);
    margin: auto;
    border: 1px solid $backgroundColor;
    border-radius: 2px;
}

