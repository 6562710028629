.webhookList {
    width: 100%;
}

.flex {
    display: flex;
    justify-content: space-between;
}

.iconMargin {
    margin-right: 6px;
}
