@import '../../../globals.module.scss';

.login {
    background-color: $backgroundColor;
    display: flex;
    min-height: 100vh;
    justify-content: center;
    align-items: center;
    color: $primText;
}

.form {
    width: 400px;
    height: 330px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $navColor;
    padding: 1rem;
    border-radius: 2px;
    border: 1px solid rgba($color: #fff, $alpha: 0.05);
}

.field {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 240px;
    margin-bottom: 1rem;
}

.input {
    margin-top: 4px;
    padding-left: 6px;
    padding-right: 6px;
    height: 32px;
    box-shadow: none;
    background-color: transparent;
    cursor: pointer;
    font-size: 14px;
    border: 1px solid rgba($color: #fff, $alpha: 0.1);
    color: $primText;
    -webkit-appearance: none;
    outline: none;
    border-radius: 2px;
}

.logo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 120px;
}

.logoImage {
    height: 50px;
}

.error {
    color: red;
    font-size: 14px;
}

.button {
    margin-top: 1rem;
    border: 1px solid rgba($color: #fff, $alpha: 0.1);
    padding-left: 18px;
    padding-right: 18px;
    height: 32px;
    box-shadow: none;
    background-color: transparent;
    cursor: pointer;
    font-size: 14px;
    border-radius: 2px;
    outline: none;
    color: $primText;
    display: flex;
    align-items: center;
    margin-left: 4px;
}
