@import '../../../globals.module.scss';

.form {
    display: flex;
    flex-direction: column;
}

.field {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 180px;
    margin-bottom: 1rem;
}

.input {
    margin-top: 4px;
    padding-left: 6px;
    padding-right: 6px;
    height: 32px;
    box-shadow: none;
    background-color: transparent;
    cursor: pointer;
    font-size: 14px;
    border: 1px solid $navColor;
    color: $primText;
    -webkit-appearance: none;
    outline: none;
    border-radius: 2px;
}

.inputError {
    @extend .input;
    border: 2px solid red;
}

.errorMessage {
    color: red;
    font-size: 12px;
}

.editors {
    display: flex;
}

.editor {
    width: 500px;
    padding: 0.5rem;
}

.button {
    margin-top: 1rem;
}
.label {
    margin-bottom: 4px;
}

.editorError {
    @extend .editor;
    border: 2px solid red;
}

.jsonEditor {
    height: 100%;
}

.ace {
    min-height: 600px;
}
