.statistics {
    display: flex;
    justify-content: space-between;
}

.statistic {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 160px;
}

.statisticGraph {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
}

.statisticTitle {
    font-size: 1.25rem;
    text-transform: uppercase;
    font-weight: 600;
}

.statisticValue {
    font-size: 2rem;
    font-weight: 400;
}

.webhookList {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.iconMargin {
    margin-right: 6px;
}

.flex {
    display: flex;
    justify-content: space-between;
}