.table {
    width: 100%;
}

.tdLeftAligned {
    text-align: start;
}

.thLeftAligned {
    text-align: start;
}

.tdRightAligned {
    text-align: end;
}

.thRightAligned {
    text-align: end;
}

.iconMargin {
    margin-right: 6px;
}

.flex {
    display: flex;
    justify-content: space-between;
}

.actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.urlTd {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 300px;
}

.clipboardButton {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    margin-right: 0.5rem;
}

.row {
    display: flex;
}

.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
}

.paginationText {
    padding-right: 1rem;
    padding-left: 1rem;
}