@import '../../../globals.module.scss';

.form {
    display: flex;
    flex-direction: column;
}

.field {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 180px;
    margin-bottom: 1rem;
}

.subscriptionField {
    @extend .field;
    max-width: unset;
}

.input {
    margin-top: 4px;
    padding-left: 6px;
    padding-right: 6px;
    height: 32px;
    box-shadow: none;
    background-color: transparent;
    cursor: pointer;
    font-size: 14px;
    border: 1px solid $navColor;
    color: $primText;
    -webkit-appearance: none;
    outline: none;
    border-radius: 2px;
}

.inputText {
    margin-top: 4px;
    padding-left: 6px;
    padding-right: 6px;
    height: 32px;
    margin-right: 0.5rem;
    box-shadow: none;
    background-color: transparent;
    cursor: pointer;
    font-size: 14px;
    border: 1px solid $backgroundColor;
    color: $primText;
    -webkit-appearance: none;
    outline: none;
    border-radius: 2px;
}

.inputError {
    @extend .input;
    border: 2px solid red;
}

.errorMessage {
    color: red;
    font-size: 12px;
}

.editors {
    display: flex;
}

.editor {
    width: 500px;
    padding: 0.5rem;
}

.button {
    margin-top: 1rem;
}
.label {
    margin-bottom: 4px;
}

.editorError {
    @extend .editor;
    border: 2px solid red;
}

.jsonEditor {
    height: 100%;
}

.subscriptions {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
}

.selects {
    margin-right: 1rem;
    padding: 1rem;
    border: 1px solid $navColor;
    min-height: 0;
    border-radius: 2px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    // display: flex;
}

.dropDownField {
    @extend .inputText;
    width: 200px;
    position: relative;
    height: 34px;
}

.dropDown {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: space-between;
    // background-color: red;
    width: calc(100% - 1rem);
    height: 100%;
    z-index: 12;
}

.dropDownMenu {
    position: absolute;
    top: 34px;
    padding-left: 6px;
    padding-right: 6px;
    border: 1px solid $backgroundColor;
    // @extend .inputText;
    color: $primText;
    -webkit-appearance: none;
    outline: none;
    border-radius: 2px;
    z-index: 13;
    background-color: #272b34;
    left: 0;
    width: calc(100% - 1rem);
    padding-right: 6px;
    max-height: 300px;
    overflow-y: auto;
    overflow-x: hidden;
}

.dropDownArrow {
    position: absolute;
    right: 0;
}

.iconMargin {
    margin-right: 6px;
}
