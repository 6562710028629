@import '../../globals.module.scss';
$activeNavItem: rgba(#fff, 0.1);

.navigation {
    text-transform: uppercase;
    font-size: 13px;
    letter-spacing: 1px;
    background-color: $navColor;
    position: fixed;
    width: 250px;
    // border-right: 1px solid $shadeColor;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.logo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 120px;
    border-bottom: 1px solid $shadeColor;
}

.logoImage {
    height: 50px;
}

.navItem {
    cursor: pointer;
    display: flex;
    align-items: center;
    border-bottom: 1px solid $shadeColor;
    height: 40px;
    padding-left: 16px;
    &:hover {
        background-color: $activeNavItem;
    }
    &Active {
        @extend .navItem;
        background-color: $activeNavItem;
    }
}

.navItem_icon {
    color: $primText;
    margin-right: 6px;
}
